dmx.slideshow.transitions.cube = function(slideshow, options) {
    return new dmx.slideshow.Transition(slideshow, Object.assign({
        requires3d: true,

        perspective: 1000,

        duration: 1500,

        direction: 'random', // left, right, up, down

        setup: function() {
            if (this.options.direction === 'random') {
                this.options.direction = this.random('left', 'right', 'up', 'down');
            }

            this.cube = document.createElement('div');
            this.cube.style.setProperty('position', 'absolute');
            this.cube.style.setProperty('width', '100%');
            this.cube.style.setProperty('height', '100%');
            this.cube.style.setProperty('transform-style', 'preserve-3d');
            this.cube.style.setProperty('transition', 'all ' + this.options.duration + 'ms ease-in-out');

            var face1 = document.createElement('div');
            face1.style.setProperty('position', 'absolute');
            face1.style.setProperty('width', '100%');
            face1.style.setProperty('height', '100%');
            face1.style.setProperty('backface-visibility', 'hidden');
            face1.style.setProperty('background-size', '100%');
            face1.style.setProperty('background-image', 'url("' + this.prevSlide.url + '")');

            var face2 = document.createElement('div');
            face2.style.setProperty('position', 'absolute');
            face2.style.setProperty('width', '100%');
            face2.style.setProperty('height', '100%');
            face2.style.setProperty('backface-visibility', 'hidden');
            face2.style.setProperty('background-size', '100%');
            face2.style.setProperty('background-image', 'url("' + this.nextSlide.url + '")');

            switch (this.options.direction) {
                case 'left':
                    face2.style.setProperty('right', 0);
                    face2.style.setProperty('transform-origin', 'left');
                    face2.style.setProperty('transform', 'translateX(100%) rotateY(90deg)');
                    break;
                case 'right':
                    face2.style.setProperty('transform-origin', 'right');
                    face2.style.setProperty('transform', 'translateX(-100%) rotateY(-90deg)');
                    break;
                case 'up':
                    face2.style.setProperty('bottom', 0);
                    face2.style.setProperty('transform-origin', 'top');
                    face2.style.setProperty('transform', 'translateY(100%) rotateX(-90deg)');
                    break;
                default:
                    face2.style.setProperty('transform-origin', 'bottom');
                    face2.style.setProperty('transform', 'translateY(-100%) rotateX(90deg)');
            }

            this.cube.appendChild(face1);
            this.cube.appendChild(face2);

            slideshow.effectsContainer.appendChild(this.cube);

            slideshow.effectsContainer.style.setProperty('overflow', 'visible');
            slideshow.effectsContainer.style.setProperty('perspective', this.options.perspective + 'px');
            slideshow.effectsContainer.style.setProperty('perspective-origin', '50% 50%');
            slideshow.effectsContainer.style.setProperty('transform-style', 'preserve-3d');

            slideshow.slidesContainer.style.setProperty('display', 'none');
        },

        execute: function() {
            var w2 = this.width / 2, h2 = this.height / 2;

            switch (this.options.direction) {
                case 'left':
                    this.cube.style.setProperty('transform', 'rotateY(-90deg) translate3d(-' + w2 + 'px, 0px, ' + w2 + 'px)');
                    break;
                case 'right':
                    this.cube.style.setProperty('transform', 'rotateY(90deg) translate3d(' + w2 + 'px, 0px, ' + w2 + 'px)');
                    break;
                case 'up':
                    this.cube.style.setProperty('transform', 'rotateX(90deg) translate3d(0px, -' + h2 + 'px, ' + h2 + 'px)');
                    break;
                default:
                    this.cube.style.setProperty('transform', 'rotateX(-90deg) translate3d(0px, ' + h2 + 'px, ' + h2 + 'px)');
            }

            this.cube.addEventListener('transitionend', this.finished.bind(this));
            setTimeout(this.finished.bind(this), this.options.duration);
        }
    }, options));
};
