dmx.slideshow.transitions.bars3d = function(slideshow, options) {
    return new dmx.slideshow.Transition(slideshow, Object.assign({
        requires3d: true,

        perspective: 800,

        bars:  10,

        delay: 150,

        duration: 800,

        direction: 'random', // left, right

        rotate: 'random', // up, down

        setup: function() {
            if (this.options.direction == 'random') {
                this.options.direction = this.random('left', 'right');
            }

            if (this.options.rotate == 'random') {
                this.options.rotate = this.random('up', 'down');
            }

            var fragment = this.createTiles(this.options.bars, 1);

            slideshow.effectsContainer.appendChild(fragment);

            slideshow.effectsContainer.style.setProperty('overflow', 'visible');
            slideshow.effectsContainer.style.setProperty('perspective', this.options.perspective + 'px');
            slideshow.effectsContainer.style.setProperty('perspective-origin', '50% 50%');
            slideshow.effectsContainer.style.setProperty('transform-style', 'preserve-3d');

            slideshow.slidesContainer.style.setProperty('display', 'none');
        },

        createTile: function(tile, x, y, width, height, left, top) {
            var face1 = document.createElement('div');
            face1.style.setProperty('position', 'absolute');
            face1.style.setProperty('width', width + 'px');
            face1.style.setProperty('height', height + 'px');
            face1.style.setProperty('backface-visibility', 'hidden');
            face1.style.setProperty('background-size', this.width + 'px');
            face1.style.setProperty('background-position', '-' + left + 'px 0px');
            face1.style.setProperty('background-image', 'url("' + this.prevSlide.url + '")');

            var face2 = document.createElement('div');
            face2.style.setProperty('position', 'absolute');
            face2.style.setProperty('width', width + 'px');
            face2.style.setProperty('height', height + 'px');
            face2.style.setProperty('backface-visibility', 'hidden');
            face2.style.setProperty('background-size', this.width + 'px');
            face2.style.setProperty('background-position', '-' + left + 'px 0px');
            face2.style.setProperty('background-image', 'url("' + this.nextSlide.url + '")');
            if (this.options.rotate == 'up') {
                face2.style.setProperty('transform-origin', 'top');
                face2.style.setProperty('transform', 'translateY(100%) rotateX(-90deg)');
            } else {
                face2.style.setProperty('transform-origin', 'bottom');
                face2.style.setProperty('transform', 'translateY(-100%) rotateX(90deg)');
            }

            var left = document.createElement('div');
            left.style.setProperty('position', 'absolute');
            left.style.setProperty('width', height + 'px');
            left.style.setProperty('height', height + 'px');
            left.style.setProperty('backface-visibility', 'hidden');
            left.style.setProperty('background', '#222');
            left.style.setProperty('transform-origin', 'right');
            left.style.setProperty('transform', 'translateX(-100%) rotateY(-90deg)');

            var right = document.createElement('div');
            right.style.setProperty('position', 'absolute');
            right.style.setProperty('width', height + 'px');
            right.style.setProperty('height', height + 'px');
            right.style.setProperty('backface-visibility', 'hidden');
            right.style.setProperty('background', '#222');
            right.style.setProperty('right', 0);
            right.style.setProperty('transform-origin', 'left');
            right.style.setProperty('transform', 'translateX(100%) rotateY(90deg)');

            tile.style.setProperty('transform-style', 'preserve-3d');
            tile.style.setProperty('transition', 'all ' + this.options.duration + 'ms ease-in-out ' + ((this.options.direction == 'left' ? this.options.bars - x - 1 : x) * this.options.delay) + 'ms');

            tile.appendChild(left);
            tile.appendChild(right);
            tile.appendChild(face1);
            tile.appendChild(face2);
        },

        execute: function() {
            var bars = slideshow.effectsContainer.childNodes;

            for (var i = 0; i < bars.length; i++) {
                bars[i].style.setProperty('transform', this.options.rotate == 'up' ? 'rotateX(90deg) translate3d(0px, -' + (this.height / 2) + 'px, ' + (this.height / 2) + 'px)' : 'rotateX(-90deg) translate3d(0px, ' + (this.height / 2) + 'px, ' + (this.height / 2) + 'px)');
            }

            bars[this.options.direction == 'left' ? 0 : bars.length - 1].addEventListener('transitionend', this.finished.bind(this));
            setTimeout(this.finished.bind(this), this.options.duration + ((bars.length - 1) * this.options.delay));
        }
    }, options));
};
