dmx.slideshow.transitions.flip = function(slideshow, options) {
    return new dmx.slideshow.Transition(slideshow, Object.assign({
        requires3d: true,

        perspective: 1300,

        duration: 1500,

        thickness: 10,

        direction: 'random', // left, right, up, down

        setup: function() {
            var prevSlide = slideshow.slides[slideshow.data.index];
            var nextSlide = slideshow.slides[this.index];

            if (this.options.direction === 'random') {
                this.options.direction = ['left', 'right', 'up', 'down'][Math.floor(Math.random() * 4)];
            }

            this.box = document.createElement('div');
            this.box.style.setProperty('position', 'absolute');
            this.box.style.setProperty('width', '100%');
            this.box.style.setProperty('height', '100%');
            this.box.style.setProperty('transform-style', 'preserve-3d');
            this.box.style.setProperty('transition', 'all ' + this.options.duration + 'ms ease-in-out');

            var front = document.createElement('div');
            front.style.setProperty('position', 'absolute');
            front.style.setProperty('width', '100%');
            front.style.setProperty('height', '100%');
            front.style.setProperty('background-size', '100%');
            front.style.setProperty('background-image', 'url("' + prevSlide.url + '")');
            front.style.setProperty('backface-visibility', 'hidden');

            var back = document.createElement('div');
            back.style.setProperty('position', 'absolute');
            back.style.setProperty('width', '100%');
            back.style.setProperty('height', '100%');
            back.style.setProperty('background-size', '100%');
            back.style.setProperty('background-image', 'url("' + nextSlide.url + '")');
            back.style.setProperty('backface-visibility', 'hidden');
            if (this.options.direction == 'left' || this.options.direction == 'right') {
                back.style.setProperty('transform', 'translateZ(-' + this.options.thickness + 'px) rotateY(180deg)');
            } else {
                back.style.setProperty('transform', 'translateZ(-' + this.options.thickness + 'px) rotateX(180deg)');
            }

            var side = document.createElement('div');
            side.style.setProperty('position', 'absolute');
            if (this.options.direction == 'left' || this.options.direction == 'right') {
                side.style.setProperty('width', this.options.thickness + 'px');
                side.style.setProperty('height', '100%');
            } else {
                side.style.setProperty('width', '100%');
                side.style.setProperty('height', this.options.thickness + 'px');
            }
            side.style.setProperty('background', '#222');
            side.style.setProperty('backface-visibility', 'hidden');
            switch (this.options.direction) {
                case 'left':
                    side.style.setProperty('right', 0);
                    side.style.setProperty('transform-origin', 'left');
                    side.style.setProperty('transform', 'translateX(100%) rotateY(90deg)');
                    break;
                case 'right':
                    side.style.setProperty('transform-origin', 'right');
                    side.style.setProperty('transform', 'translateX(-100%) rotateY(-90deg)');
                    break;
                case 'up':
                    side.style.setProperty('bottom', 0);
                    side.style.setProperty('transform-origin', 'top');
                    side.style.setProperty('transform', 'translateY(100%) rotateX(-90deg)');
                    break;
                default:
                    side.style.setProperty('transform-origin', 'bottom');
                    side.style.setProperty('transform', 'translateY(-100%) rotateX(90deg)');
            }

            this.box.appendChild(front);
            this.box.appendChild(back);
            this.box.appendChild(side);

            slideshow.effectsContainer.appendChild(this.box);

            slideshow.effectsContainer.style.setProperty('overflow', 'visible');
            slideshow.effectsContainer.style.setProperty('perspective', this.options.perspective + 'px');
            slideshow.effectsContainer.style.setProperty('perspective-origin', '50% 50%');
            slideshow.effectsContainer.style.setProperty('transform-style', 'preserve-3d');

            slideshow.slidesContainer.style.setProperty('display', 'none');
        },

        execute: function() {
            switch (this.options.direction) {
                case 'left':
                    this.box.style.setProperty('transform', 'rotateY(-180deg) translateZ(' + this.options.thickness + 'px)');
                    break;
                case 'right':
                    this.box.style.setProperty('transform', 'rotateY(180deg) translateZ(' + this.options.thickness + 'px)');
                    break;
                case 'up':
                    this.box.style.setProperty('transform', 'rotateX(180deg) translateZ(' + this.options.thickness + 'px)');
                    break;
                default:
                    this.box.style.setProperty('transform', 'rotateX(-180deg) translateZ(' + this.options.thickness + 'px)');
            }

            this.box.addEventListener('transitionend', this.finished.bind(this));
            setTimeout(this.finished.bind(this), this.options.duration);
        }
    }, options));
};
