dmx.slideshow.transitions.fold = function(slideshow, options) {
    return new dmx.slideshow.Transition(slideshow, Object.assign({
        requires3d: true,

        perspective: 1000,

        duration: 1500,

        direction: 'random', // left, right

        setup: function() {
            if (this.options.direction === 'random') {
                this.options.direction = this.random('left', 'right');
            }

            this.slice1 = document.createElement('div');
            this.slice1.style.setProperty('position', 'absolute');
            this.slice1.style.setProperty('width', '25%');
            this.slice1.style.setProperty('height', '100%');
            this.slice1.style.setProperty('left', this.options.direction == 'left' ? '0' : '75%');
            this.slice1.style.setProperty('backface-visibility', 'hidden');
            this.slice1.style.setProperty('transform-style', 'preserve-3d');
            this.slice1.style.setProperty('transform-origin', this.options.direction == 'left' ? '0% 50%' : '100% 0%');
            this.slice1.style.setProperty('transition', 'all ' + this.options.duration + 'ms ease-in-out');
            this.slice1.style.setProperty('background-size', this.width + 'px')
            this.slice1.style.setProperty('background-image', 'url("' + this.prevSlide.url + '")');
            this.slice1.style.setProperty('background-position', this.options.direction == 'left' ? '0% 0%' : '-300% 0%');

            this.slice2 = this.slice1.cloneNode();
            this.slice2.style.setProperty('width', '100%');
            this.slice2.style.setProperty('left', this.options.direction == 'left' ? '100%' : '-100%');
            this.slice2.style.setProperty('background-position', this.options.direction == 'left' ? '-100% 0%' : '-200% 0%');

            this.slice3 = this.slice2.cloneNode();
            this.slice3.style.setProperty('background-position', this.options.direction == 'left' ? '-200% 0%' : '-100% 0%');

            this.slice4 = this.slice3.cloneNode();
            this.slice4.style.setProperty('background-position', this.options.direction == 'left' ? '-300% 0%' : '0% 0%');

            this.slice5 = this.slice4.cloneNode();
            this.slice5.style.setProperty('background-image', 'url("' + this.nextSlide.url + '")');
            this.slice5.style.setProperty('background-position', this.options.direction == 'left' ? '0% 0%' : '-300% 0%');
            this.slice5.style.setProperty('transform', this.options.direction == 'left' ? 'rotateY(179deg)' : 'rotateY(-179deg)');

            this.slice6 = this.slice5.cloneNode();
            this.slice6.style.setProperty('background-position', this.options.direction == 'left' ? '-100% 0%' : '-200% 0%');
            this.slice6.style.setProperty('transform', this.options.direction == 'left' ? 'rotateY(-180deg)' : 'rotateY(180deg)');

            this.slice7 = this.slice6.cloneNode();
            this.slice7.style.setProperty('background-position', this.options.direction == 'left' ? '-200% 0%' : '-100% 0%');
            this.slice7.style.setProperty('transform', this.options.direction == 'left' ? 'rotateY(180deg)' : 'rotateY(-180deg)');

            this.slice8 = this.slice7.cloneNode();
            this.slice8.style.setProperty('background-position', this.options.direction == 'left' ? '-300% 0%' : '0% 0%');
            this.slice8.style.setProperty('transform', this.options.direction == 'left' ? 'rotateY(-180deg)' : 'rotateY(180deg)');

            this.slice1.appendChild(this.slice2);
            this.slice2.appendChild(this.slice3);
            this.slice3.appendChild(this.slice4);
            this.slice4.appendChild(this.slice5);
            this.slice5.appendChild(this.slice6);
            this.slice6.appendChild(this.slice7);
            this.slice7.appendChild(this.slice8);

            slideshow.effectsContainer.appendChild(this.slice1);

            slideshow.effectsContainer.style.setProperty('overflow', 'visible');
            slideshow.effectsContainer.style.setProperty('perspective', this.options.perspective + 'px');
            slideshow.effectsContainer.style.setProperty('perspective-origin', '50% 50%');

            slideshow.slidesContainer.style.setProperty('display', 'none');
        },

        execute: function() {
            this.slice1.style.setProperty('transform', this.options.direction == 'left' ? 'rotateY(90deg)' : 'rotateY(-90deg)');
            this.slice2.style.setProperty('transform', this.options.direction == 'left' ? 'rotateY(-180deg)' : 'rotateY(180deg)');
            this.slice3.style.setProperty('transform', this.options.direction == 'left' ? 'rotateY(180deg)' : 'rotateY(-180deg)');
            this.slice4.style.setProperty('transform', this.options.direction == 'left' ? 'rotateY(-180deg)' : 'rotateY(180deg)');
            this.slice5.style.setProperty('transform', this.options.direction == 'left' ? 'rotateY(90deg)' : 'rotateY(-90deg)');
            this.slice6.style.setProperty('transform', 'rotateY(0deg)');
            this.slice7.style.setProperty('transform', 'rotateY(0deg)');
            this.slice8.style.setProperty('transform', 'rotateY(0deg)');
            this.slice1.addEventListener('transitionend', this.finished.bind(this));
            setTimeout(this.finished.bind(this), this.options.duration);
        }
    }, options));
};
