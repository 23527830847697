dmx.slideshow.transitions.tiles3d = function(slideshow, options) {
    return new dmx.slideshow.Transition(slideshow, Object.assign({
        requires3d: true,

        perspective: 800,

        cols: 10,

        rows: 'auto',

        delay: 150,

        duration: 800,

        thickness: 10,

        setup: function() {
            var fragment = this.createTiles(this.options.cols, this.options.rows);

            slideshow.effectsContainer.appendChild(fragment);

            slideshow.effectsContainer.style.setProperty('overflow', 'visible');
            slideshow.effectsContainer.style.setProperty('perspective', this.options.perspective + 'px');
            slideshow.effectsContainer.style.setProperty('perspective-origin', '50% 50%');
            slideshow.effectsContainer.style.setProperty('transform-style', 'preserve-3d');

            slideshow.slidesContainer.style.setProperty('display', 'none');
        },

        createTile: function(tile, x, y, width, height, left, top) {
            var front = document.createElement('div');
            front.style.setProperty('position', 'absolute');
            front.style.setProperty('width', width + 'px');
            front.style.setProperty('height', height + 'px');
            front.style.setProperty('backface-visibility', 'hidden');
            front.style.setProperty('background-size', this.width + 'px');
            front.style.setProperty('background-position', '-' + left + 'px -' + top + 'px');
            front.style.setProperty('background-image', 'url("' + this.prevSlide.url + '")');

            var back = document.createElement('div');
            back.style.setProperty('position', 'absolute');
            back.style.setProperty('width', width + 'px');
            back.style.setProperty('height', height + 'px');
            back.style.setProperty('backface-visibility', 'hidden');
            back.style.setProperty('background-size', this.width + 'px');
            back.style.setProperty('background-position', '-' + left + 'px -' + top + 'px');
            back.style.setProperty('background-image', 'url("' + this.nextSlide.url + '")');
            back.style.setProperty('transform', 'translateZ(-' + this.options.thickness + 'px) rotateY(180deg)');

            var side = document.createElement('div');
            side.style.setProperty('position', 'absolute');
            side.style.setProperty('width', this.options.thickness + 'px');
            side.style.setProperty('height', height + 'px');
            side.style.setProperty('background', '#222');
            side.style.setProperty('backface-visibility', 'hidden');
            side.style.setProperty('transform-origin', 'right');
            side.style.setProperty('transform', 'translateX(-100%) rotateY(-90deg)');

            var top = document.createElement('div');
            top.style.setProperty('position', 'absolute');
            top.style.setProperty('width', width + 'px');
            top.style.setProperty('height', this.options.thickness + 'px');
            top.style.setProperty('background', '#222');
            top.style.setProperty('backface-visibility', 'hidden');
            top.style.setProperty('transform-origin', 'bottom');
            top.style.setProperty('transform', 'translateY(-100%) rotateX(90deg)');

            var bottom = document.createElement('div');
            bottom.style.setProperty('position', 'absolute');
            bottom.style.setProperty('width', width + 'px');
            bottom.style.setProperty('height', this.options.thickness + 'px');
            bottom.style.setProperty('bottom', 0);
            bottom.style.setProperty('background', '#222');
            bottom.style.setProperty('backface-visibility', 'hidden');
            bottom.style.setProperty('transform-origin', 'top');
            bottom.style.setProperty('transform', 'translateY(100%) rotateX(-90deg)');

            tile.style.setProperty('transform-style', 'preserve-3d');
            tile.style.setProperty('transition', 'all ' + this.options.duration + 'ms ease-out ' + ((x + y) * this.options.delay) + 'ms');

            tile.appendChild(side);
            tile.appendChild(top);
            tile.appendChild(bottom);
            tile.appendChild(front);
            tile.appendChild(back);
        },

        execute: function() {
            var tiles = slideshow.effectsContainer.childNodes;

            for (var i = 0; i < tiles.length; i++) {
                tiles[i].style.setProperty('transform', 'rotateY(180deg) translateZ(' + this.options.thickness + 'px)');
            }

            tiles[tiles.length - 1].addEventListener('transitionend', this.finished.bind(this));
            setTimeout(this.finished.bind(this), this.options.duration + (tiles.length * this.options.delay));
        }
    }, options));
};
