dmx.slideshow.transitions.blinds3d = function(slideshow, options) {
    return new dmx.slideshow.Transition(slideshow, Object.assign({
        requires3d: true,

        perspective: 800,

        bars: 10,

        delay: 150,

        duration: 800,

        thickness: 10,

        direction: 'random', // left, right

        setup: function() {
            if (this.options.direction == 'random') {
                this.options.direction = this.random('left', 'right');
            }

            var fragment = this.createTiles(this.options.bars, 1);

            slideshow.effectsContainer.appendChild(fragment);

            slideshow.effectsContainer.style.setProperty('overflow', 'visible');
            slideshow.effectsContainer.style.setProperty('perspective', this.options.perspective + 'px');
            slideshow.effectsContainer.style.setProperty('perspective-origin', '50% 50%');
            slideshow.effectsContainer.style.setProperty('transform-style', 'preserve-3d');

            slideshow.slidesContainer.style.setProperty('display', 'none');
        },

        createTile: function(tile, x, y, width, height, left, top) {
            var front = document.createElement('div');
            front.style.setProperty('position', 'absolute');
            front.style.setProperty('width', width + 'px');
            front.style.setProperty('height', height + 'px');
            front.style.setProperty('backface-visibility', 'hidden');
            front.style.setProperty('background-size', this.width + 'px');
            front.style.setProperty('background-position', '-' + left + 'px 0px');
            front.style.setProperty('background-image', 'url("' + this.prevSlide.url + '")');

            var back = document.createElement('div');
            back.style.setProperty('position', 'absolute');
            back.style.setProperty('width', width + 'px');
            back.style.setProperty('height', height + 'px');
            back.style.setProperty('backface-visibility', 'hidden');
            back.style.setProperty('background-size', this.width + 'px');
            back.style.setProperty('background-position', '-' + left + 'px 0px');
            back.style.setProperty('background-image', 'url("' + this.nextSlide.url + '")');
            back.style.setProperty('transform', 'translateZ(-' + this.options.thickness + 'px) rotateY(180deg)');

            var side = document.createElement('div');
            side.style.setProperty('position', 'absolute');
            side.style.setProperty('width', this.options.thickness + 'px');
            side.style.setProperty('height', height + 'px');
            side.style.setProperty('background', '#222');
            side.style.setProperty('backface-visibility', 'hidden');
            if (this.options.direction == 'left') {
                side.style.setProperty('right', 0);
                side.style.setProperty('transform-origin', 'left');
                side.style.setProperty('transform', 'translateX(100%) rotateY(90deg)');
            } else {
                side.style.setProperty('transform-origin', 'right');
                side.style.setProperty('transform', 'translateX(-100%) rotateY(-90deg)');
            }

            tile.style.setProperty('transform-style', 'preserve-3d');
            tile.style.setProperty('transition', 'all ' + this.options.duration + 'ms ease-in-out ' + ((this.options.direction == 'left' ? this.options.bars - x - 1 : x) * this.options.delay) + 'ms');

            tile.appendChild(front);
            tile.appendChild(back);
            tile.appendChild(side);
        },

        execute: function() {
            var blinds = slideshow.effectsContainer.childNodes;

            for (var i = 0; i < blinds.length; i++) {
                blinds[i].style.setProperty('transform', this.options.direction == 'left' ? 'rotateY(-180deg) translateZ(' + this.options.thickness + 'px)' : 'rotateY(180deg) translateZ(' + this.options.thickness + 'px)');
            }

            blinds[this.options.direction == 'left' ? 0 : blinds.length - 1].addEventListener('transitionend', this.finished.bind(this));
            setTimeout(this.finished.bind(this), this.options.duration + ((blinds.length - 1) * this.options.delay));
        }
    }, options));
};
