dmx.slideshow.transitions.turn = function(slideshow, options) {
    return new dmx.slideshow.Transition(slideshow, Object.assign({
        requires3d: true,

        perspective: 1300,

        duration: 1500,

        direction: 'random', // left, right

        setup: function() {
            if (this.options.direction === 'random') {
                this.options.direction = this.random('left', 'right');
            }

            this.forwards = this.options.direction == 'left';

            var left = document.createElement('div');
            left.style.setProperty('position', 'absolute');
            left.style.setProperty('width', '50%');
            left.style.setProperty('height', '100%');
            left.style.setProperty('background-size', this.width + 'px');
            left.style.setProperty('background-image', 'url("' + (this.forwards ? this.prevSlide : this.nextSlide).url + '")');

            var right = document.createElement('div');
            right.style.setProperty('position', 'absolute');
            right.style.setProperty('width', '50%');
            right.style.setProperty('height', '100%');
            right.style.setProperty('background-size', this.width + 'px');
            right.style.setProperty('background-image', 'url("' + (this.forwards ? this.nextSlide : this.prevSlide).url + '")');
            right.style.setProperty('background-position', '100% 0%');
            right.style.setProperty('left', '50%');

            this.page = document.createElement('div');
            this.page.style.setProperty('position', 'absolute');
            this.page.style.setProperty('width', '50%');
            this.page.style.setProperty('height', '100%');
            this.page.style.setProperty('left', this.options.direction == 'left' ? '50%' : 0);
            this.page.style.setProperty('transform-style', 'preserve-3d');
            this.page.style.setProperty('transition', 'all ' + this.options.duration + 'ms ease-in-out');
            this.page.style.setProperty('transform-origin', this.forwards ? 'left center' : 'right center');
            this.page.style.setProperty('z-index', 100);

            var front = document.createElement('div');
            front.style.setProperty('position', 'absolute');
            front.style.setProperty('width', '100%');
            front.style.setProperty('height', '100%');
            front.style.setProperty('-webkit-backface-visibility', 'hidden');
            front.style.setProperty('backface-visibility', 'hidden');
            front.style.setProperty('background-size', this.width + 'px');
            front.style.setProperty('background-image', 'url("' + this.prevSlide.url + '")');
            front.style.setProperty('background-position', this.forwards ? '-100% 0%' : '0% 0%');

            var back = document.createElement('div');
            back.style.setProperty('position', 'absolute');
            back.style.setProperty('width', '100%');
            back.style.setProperty('height', '100%');
            back.style.setProperty('-webkit-backface-visibility', 'hidden');
            back.style.setProperty('backface-visibility', 'hidden');
            back.style.setProperty('background-size', this.width + 'px');
            back.style.setProperty('background-image', 'url("' + this.nextSlide.url + '")');
            back.style.setProperty('background-position', this.forwards ? '0% 0%' : '-100% 0%');
            back.style.setProperty('transform', 'rotateY(180deg)');

            this.page.appendChild(front);
            this.page.appendChild(back);

            slideshow.effectsContainer.appendChild(left);
            slideshow.effectsContainer.appendChild(right);
            slideshow.effectsContainer.appendChild(this.page);

            slideshow.effectsContainer.style.setProperty('overflow', 'visible');
            slideshow.effectsContainer.style.setProperty('perspective', this.options.perspective + 'px');
            slideshow.effectsContainer.style.setProperty('perspective-origin', '50% 50%');

            slideshow.slidesContainer.style.setProperty('display', 'none');
        },

        execute: function() {
            this.page.style.setProperty('transform', 'rotateY(' + (this.forwards ? -179 : 179) + 'deg)');
            this.page.addEventListener('transitionend', this.finished.bind(this));
            setTimeout(this.finished.bind(this), this.options.duration);
        }
    }, options));
};
